.woocommerce-checkout {
    .main {
        @include make-container();

        .woocommerce {
            max-width: $container-md;
            margin: 0 auto;
        }
    }

    h3 {
        font-size: $font-size-large;
    }

    form.checkout {
        .woocommerce-checkout-review-order-table {
            td {
                &.product-name {
                    .wc-gzd-product-name-left {
                        display: none;
                    }
                    @media screen and (min-width: $screen-is-min) {
                        .wc-gzd-product-name-left {
                            display: block;
                        }
                        .wc-gzd-product-name-right {
                            max-width: 75%;
                        }
                    }
                }
            }
        }
    }
}

.woocommerce-order {
    .woocommerce-notice {
        &.woocommerce-notice--success {
            font-size: $font-size-h2;
            color: $color-success;
        }
    }
}
