// Table styles
.table-centered {
    width: auto !important;
    margin: 0 auto;

    td {
        padding: 0 .5em;

        @media screen and (max-width: $screen-xs-max) {
            display: block;
            height: auto;
            padding: .5em 0;
        }
    }
}
