// Grid settings

$main-sm-columns:       12;
$sidebar-sm-columns:    3;

$copyright-line-sm-columns: 6;


//
// Variables
// --------------------------------------------------


// Place your custom variables like brand colors etc. here.

$color-primary:              #d84236;
$color-secondary:            #4b4b4b;
$color-tertiary:             #009fe2;
$color-quaternary:           #ae8426;
$color-quinary:              #fbf7ee;
$color-senary:               #5c5956;
$color-septenary:            #f39a83;
   

// Lighten Colors
$color-primary-lighten:      lighten($color-primary, 10%);
$color-secondary-lighten:    lighten($color-secondary, 10%);
$color-tertiary-lighten:     lighten($color-tertiary, 10%);
$color-quaternary-lighten:   lighten($color-quaternary, 10%);
$color-quinary-lighten:      lighten($color-quinary, 10%);
$color-senary-lighten:       lighten($color-senary, 10%);

// Darken Colors
$color-primary-darken:      darken($color-primary, 10%);
$color-secondary-darken:    darken($color-secondary, 10%);
$color-tertiary-darken:     darken($color-tertiary, 10%);
$color-quaternary-darken:   darken($color-quaternary, 10%);
$color-quinary-darken:      darken($color-quinary, 10%);

$color-success:             #77a464;
$color-success-lighten:     lighten(#77a464, 10%);

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         $color-primary;



//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            $color-secondary !default;

//** Global textual link color.
$link-color:            $color-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      lighten($link-color, 10%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:          18px !default;
$font-size-big:           ceil(($font-size-base * 2)) !default; // ~36px
$font-size-large:         ceil(($font-size-base * 1.5)) !default; // ~30px
$font-size-small:         ceil(($font-size-base * 0.8)) !default; // ~16px
$font-size-tiny:          ceil(($font-size-base * 0.6)) !default; // ~14px

$font-size-h1:            floor(($font-size-base * 2)) !default; // ~40px
$font-size-h2:            $font-size-large;
$font-size-h3:            ceil(($font-size-base * 1.2)) !default; // ~24px
$font-size-h4:            ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5:            $font-size-base !default;
$font-size-h6:            ceil(($font-size-base * 0.85)) !default; // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family:    inherit !default;
$headings-font-weight:    500 !default;
$headings-line-height:    1.1 !default;
$headings-color:          inherit !default;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    #333 !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $brand-primary !default;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px !default;


//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px !default;

//** Default background color used for all tables.
$table-bg:                      transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            #ddd !default;


//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight:                normal !default;

$btn-default-color:              #333 !default;
$btn-default-bg:                 #fff !default;
$btn-default-border:             #ccc !default;

$btn-primary-color:              #fff !default;
$btn-primary-bg:                 $brand-primary !default;
$btn-primary-border:             darken($btn-primary-bg, 5%) !default;

$btn-link-disabled-color:        $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base:         $border-radius-base !default;
$btn-border-radius-large:        $border-radius-large !default;
$btn-border-radius-small:        $border-radius-small !default;


//== Forms
//
//##

//** `<input>` background color
$input-bg:                       #fff !default;
//** `<input disabled>` background color
$input-bg-disabled:              $gray-lighter !default;

//** Text color for `<input>`s
$input-color:                    $gray !default;
//** `<input>` border color
$input-border:                   #ccc !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            $border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large:      $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small:      $border-radius-small !default;

//** Border color for inputs on focus
$input-border-focus:             $color-primary !default;

//** Placeholder text color
$input-color-placeholder:        #999 !default;

//** Default `.form-control` height
$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;

//** `.form-group` margin
$form-group-margin-bottom:       15px !default;

$legend-color:                   $gray-dark !default;
$legend-border-color:            #e5e5e5 !default;

//** Background color for textual input addons
$input-group-addon-bg:           $gray-lighter !default;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed !default;





//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000 !default;
$zindex-dropdown:          1000 !default;
$zindex-popover:           1060 !default;
$zindex-tooltip:           1070 !default;
$zindex-navbar-fixed:      1030 !default;
$zindex-modal-background:  1040 !default;
$zindex-modal:             1050 !default;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.


// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  0px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Intermediate small screen / phone. (Custom breakpoint).
$screen-is:                  480px;
// Minimum screen size for the custom grid.
$screen-is-min:              ($screen-is);
// Maximum screen size for Extra small screen / phone (We have to call it before bootstrap).
$screen-phablet:             $screen-is-min;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
// Minimum screen size for Medium screen / tablet.
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-is-min - 1) !default;
$screen-is-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;



//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (940px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


// Mobile Nav Topbar variables
$mobile-nav-topbar-color:               $text-color;
$mobile-nav-topbar-bg:                  #ffffff;
$mobile-nav-topbar-link-color:          $text-color;
$mobile-nav-topbar-link-hover-color:    $text-color;
$mobile-nav-topbar-link-hover-bg:       transparent;

// Desktop Nav Variables
$desktop-nav-color:             $color-secondary !default;
$desktop-nav-bg:                #fff !default;
$desktop-nav-border:            darken($desktop-nav-bg, 6.5%) !default;

// Navbar links
$desktop-nav-link-color:                $desktop-nav-color !default;
$desktop-nav-link-hover-color:          $color-primary !default;
$desktop-nav-link-hover-bg:             $gray-lighter !default;
$desktop-nav-link-active-color:         $color-primary !default;
$desktop-nav-link-active-bg:            $color-secondary !default;
$desktop-nav-link-disabled-color:       #ccc !default;
$desktop-nav-link-disabled-bg:          transparent !default;

//=== Offcanvas Styles
$offcanvas-color:               #777 !default;
$offcanvas-bg:                  #fff !default;
$offcanvas-width:               82%;
$offcanvas-duration:            300ms;
$offcanvas-delay:               0s;
$offcanvas-timing:              ease-in;

// Offcanvas links
$offcanvas-link-color:                #777 !default;
$offcanvas-link-hover-color:          #333 !default;
$offcanvas-link-hover-bg:             $gray-lighter !default;
$offcanvas-link-active-color:         #555 !default;
$offcanvas-link-active-bg:            darken($desktop-nav-bg, 6.5%) !default;
$offcanvas-link-disabled-color:       #ccc !default;
$offcanvas-link-disabled-bg:          transparent !default;
$offcanvas-width:                     82%;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          5px !default;
$nav-link-hover-bg:                         $gray-lighter !default;

$nav-disabled-link-color:                   $gray-light !default;
$nav-disabled-link-hover-color:             $gray-light !default;


//== Tooltips
//
//##

//** Tooltip max width
$tooltip-max-width:           200px !default;
//** Tooltip text color
$tooltip-color:               #fff !default;
//** Tooltip background color
$tooltip-bg:                  #000 !default;
$tooltip-opacity:             .9 !default;

//** Tooltip arrow width
$tooltip-arrow-width:         5px !default;
//** Tooltip arrow color
$tooltip-arrow-color:         $tooltip-bg !default;


//== Breadcrumbs
//
//##

$breadcrumb-padding-vertical:   8px !default;
$breadcrumb-padding-horizontal: 15px !default;
//** Breadcrumb background color
$breadcrumb-bg:                 #f5f5f5 !default;
//** Breadcrumb text color
$breadcrumb-color:              #ccc !default;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color:       $gray-light !default;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator:          "/" !default;
