/****************************************************
************ Topbar Styles (mobile only) ************
*****************************************************/
.page-wrap {
    @media screen and (max-width: $screen-is-max) {
        padding-top: 50px;
    }
}




/****************************************************
************ Topbar Styles (mobile only) ************
*****************************************************/
.topbar {
    @include container-fixed();
    @extend .visible-xs;
    @extend .visible-is;
    position: fixed;
    top: 0;
    z-index: 1010;
    margin-bottom: 0;
    width: 100%;
    background: $mobile-nav-topbar-bg;
    color: $mobile-nav-topbar-color;
    font-size: 20px;
    transform: translateY(0);
    -ms-transform: translateY(0);
    transition: all 200ms ease-in;

    &.slide-up {
        transform: translateY(-100%);
        -ms-transform: translateY(-100%);
    }

    .inner {
        text-align: justify;
        font-size: 0;

        &::after {
            display: inline-block;
            content: ' ';
            width: 100%;
        }

        // Default styles of topbar components
        > div {
            display: inline-block;
            width: 33.33%;
            line-height: 50px;
            vertical-align: middle;

            // Mobile header contains icons like tel/e-mail
            &.mobile-header {

                .nav {
                    line-height: 1;

                    > li {
                        display: inline-block;

                        &:last-child {
                            margin: 0;
                        }

                        a {
                            padding: 0;
                            color: $mobile-nav-topbar-link-color;
                            line-height: 1;
                            margin-right: .5rem;

                            &:hover {
                                background: $mobile-nav-topbar-link-hover-bg;
                                color: $mobile-nav-topbar-link-hover-color;
                            }

                            i {
                                font-size: 1.4em;
                            }

                            > span {
                                display: none;
                            }
                        }
                    }
                }
            }

            // Mobile logo contains only the site-logo
            &.mobile-logo {
                margin: 0;

                .logo-link {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    vertical-align: middle;

                    img {
                        display: inline-block;
                        width: auto;
                        max-height: 100%;
                    }
                }
            }

            // Menu toggle contains the nav toggle elements
            &.menu-toggle {
            }
        }
    }
}


/****************************************************
*** Customize the topbar nav Toggle (mobile only) ***
****************************************************/
#nav-toggle {
    position: relative;
    float: right;
    margin: 0;
    border: none;
    padding: 0 0 .25em;
    background-color: transparent;
    background-image: none;

    &:focus {
        outline: 0;
    }

    .icon-bar {
        display: block;
        border-radius: 1px;
        width: 30px;
        height: 4px;
        background-color: $mobile-nav-topbar-link-color;
        transition: all 0.2s;
    }
    .top-bar {
        transform: rotate(0);
        -ms-transform: rotate(0);
    }
    .middle-bar {
        opacity: 1;
    }
    .bottom-bar {
        transform: rotate(0);
        -ms-transform: rotate(0);
    }
    .icon-bar+.icon-bar {
        margin-top: 5px;
    }

    // 'Menu' Hint next to the topbar nav toggle button
    .menu-labels {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: $font-size-base;
        line-height: 1;

        .closed {
            position: absolute;
            top: 2px;
            left: -175%;
            text-transform: uppercase;
            transition: opacity $offcanvas-duration ease-in-out;
        }
    }

    .move-left &,
    .move-right &,
    .nav-expanded & {
        .top-bar {
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform-origin: 5% 5%;
        }
        .middle-bar {
            opacity: 0;
        }
        .bottom-bar {
            transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform-origin: 5% 85%;
        }
        .closed {
            opacity: 0;
        }
    }
}
