/****************************************************
*************** General Footer Styles ***************
****************************************************/
#footer {

    background-color: $color-primary;

}




/****************************************************
******************** Footer Menu ********************
****************************************************/
.footer-menu {
    @extend .hidden-xs;
    @extend .hidden-is;

    nav {
        @extend .container;

        #footer-nav {
            align-items: flex-start;

            // Generic #footer-nav list-item styles
            li {
                flex: 0 1 auto;
                text-align: left;
                vertical-align: top;

                &.current-menu-item > a {
                    font-weight: bold;
                }
            }

            > li {
                // First Level Sub-Menus
                > .sub-menu {
                    .menu-item a {
                        font-size: $font-size-small;
                    }
                }
            }
        }
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-social-links {
    @extend .container;
    padding: $grid-gutter-width/2 0;
    text-align: center;

    h4 {
        margin: 0 0 $grid-gutter-width/2;
    }

    .social-links-list {
        @extend .nav;
        @extend .nav-center;
    }
}




/****************************************************
************ Footer Widget Area Styles **************
****************************************************/
.footer-widgets {
    @extend .container;
}




/****************************************************
******** Copyright Line (Last Line of site) *********
****************************************************/
.copyright {
    padding-bottom: 2.5em;

    .cookies-not-accepted & {
        padding-bottom: 5em;

        @media screen and (min-width: $screen-sm-min){
            padding-bottom: 3em
        }
    }
}

.copyright-row {
    @extend .row;
    padding-top: $grid-gutter-width/2;
    font-size: $font-size-small;
    text-align: center;

    ul li {
        @extend .pipe-before;
        float: left;

        @media screen and (max-width: $screen-is-max){
            float: none;

            &::after,
            &::before {
                display: none;
            }
        }
    }

    // Unternehmensname, Copyright, Adresse etc.
    .copyright-info {
        @include list-unstyled;
        @include make-sm-column(12);
        padding-bottom: $grid-gutter-width/2;
        text-align: center;
        display: inline-block !important;
        float: none !important;
        width: auto !important;
        margin: 0 auto !important;

        @media screen and (min-width: $screen-sm-min){
            text-align: center;
        }

        li {
            margin: 0;
            font-size: $font-size-small;
            color: #fff;

            a {

                color: #fff;

            }

        }
    }

    // Copyright Navigation (Kontakt, Impressum, Datenschutz etc.)
    .copyright-navigation {
        float: none !important;
        display: inline-block !important;
        @include make-sm-column(12);
        padding-bottom: $grid-gutter-width/2;
        text-align: center;

        .menu {
            @include list-unstyled;
            display: inline-block !important;

            @media screen and (max-width: $screen-is-max){
                float: none;

                li {
                    float: none;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}

#menu-copyright-navigation {

    li {

        a {

            color: #fff !important;

        }

        &::before {

            color: #fff !important;

        }

    }

}

