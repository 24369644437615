body {

}

h1 {
    font-weight: 800 !important;
}
h2 {
    font-weight:600 !important;
}
.fl-module-uabb-heading {
    &.change {
    .uabb-module-content {
        display:flex;
        flex-direction: column-reverse;
        .uabb-heading {
            color:#d84236;
            font-size:18px;
            font-size:1.8rem;
            font-weight:200;
        }
        .uabb-subheading {
            margin-bottom:13px;
            font-size:26px;
            font-weight:600;
            color:#454545;
            font-size:3.6rem;
        }
    }
    }
}

ul {

    padding: 0 0 0 20px;

    li {

        margin: 0.5em 0;

    }

}

.fl-rich-text {

    a {

        font-weight: 300 !important;
        text-decoration: underline !important;

    }

    p {

        > a {

            font-weight: 300 !important;
            text-decoration: underline !important;

        }

    }

}

.content-wrap,
#tinymce {
    h1,
    h2,
    h3,
    h4 {
    }

    h1{
        font-weight: 600;
    }

    h2 {

    }

    h3 {

    }

    h4 {

    }
}

.tagline{
    font-weight:300;
    font-size: $font-size-large;
}

.steps {
    color: $color-primary;
    font-weight: bold;
}