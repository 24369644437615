#header {
    @extend .hidden-xs;
    @extend .hidden-is;

    padding: 0.5em 0 1.5em 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition:150ms ease-in-out all;
    &.affix {
        padding:.2em 0 .8em 0;
        .header-content {
            .site-logo {
                width:25%;
                margin-top:-20px;
            }
        }
    }

    // First line of the website. Often contains contact information
    .header-contact {
        font-size: $font-size-small;

        .opening-times {
            @include make-sm-column(5);
        }

        .contact-infos {

            float: right !important;

            ul {
                @extend .nav;
                @extend .nav-right;
                line-height: 1;

                li {
                    font-size: $font-size-small;

                    i {
                        color: $color-primary;
                        margin-right: 0.5em;
                    }

                    a {
                        padding-right:15px;
                        color: $color-secondary;

                    }

                    a,
                    i {
                        display: inline-block;
                        font-size: $font-size-small;
                        vertical-align: middle;

                        &::before {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }

    // Wrapper for site logo and header widgets
    .header-content {
        >.container {
            position:relative;
        }
        .site-logo {
            width:30%;
            float:left;
            transition:150ms ease-in-out all;
            img {
                width:100%;
            }
            .logo-link {
                display: block;
                height: 100px;
                background-color: $brand-primary;
            }

        }

        .header-widgets {
            @include make-sm-column(2);
        }

        .header-navigation {
            @include make-sm-column(6);

            ul {
                &.stacked-icons {
                    i {
                        display: block;
                        margin: 0;
                        text-align: center;
                    }
                }
            }
        }
    }
}
