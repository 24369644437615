/* Default structural markup by the pagebuilder
* Referenced from here https://www.wpbeaverbuilder.com/knowledge-base/html-reference/
*/

// There is only one layout element per page that contains all of the other builder elements.
.fl-builder-content {}


// Rows are child elements of the main layout and contain column groups.
.fl-row {
    > div {
        padding-top: 3em;
        padding-bottom: 3em;
    }

    .fl-builder-edit & {

        .fl-block-overlay {
            padding: 0;
        }
    }

// Remove padding-top of the first row-content-wrap if breadcrumb is present
    .has-breadcrumb & {
        &:first-child {
            .fl-row-content-wrap {
                padding-top: 0;
            }
        }
    }
}

.fl-row.row-quote {
    > div {
        padding: 0;
        background-color: #F39A83;
        p {
            margin: 3rem;
            padding: 0;
            color: #fff;
            font-size: 3rem;
        }
    }
}

// Column groups can be thought of as rows within rows. They can contain any number of columns.
.fl-col-group {
    @extend .row; // Add bs3 row styles

    &.fl-col-group-equal-height {
        &::before, &::after {
            content: none;
        }
    }

    &.fl-col-group-nested {
        margin: auto;
    }

    .fl-responsive-preview-content & {
        width: auto;
    }
}

/* Columns come in many shapes and sizes and can contain as many modules as you want to put in them.
 * All columns are stacked when they become responsive for smaller screen sizes.
 * Larger columns become 100% width when viewed on smaller screens while smaller columns have a max with of 400px.
 */
.fl-col {}

.fl-col-small {
    max-width: none;
}
.fl-col-content {}



/* Modules are the last level of elements that can be added to a builder layout.
*They contain the dynamically generated markup for each unique module.
*/
.fl-module {}
.fl-module-content {}



/* Custom grid settings. Add container styles to fixed-width rows on any desired template, according to body class.
* Make sure you choose "row-width -> full-width" and "content-width -> fixed width" in row-settings on full-width templates for boxed content
* For full width layouts on full-width-templates and for templates with sidebar choose "content-size -> full-width" in row-settings
*/

.no-sidebar-primary {

    .fl-row-fixed-width.fl-row-content {
        @extend .container;
    }

    .fl-responsive-preview-content {
        .fl-row-fixed-width.fl-row-content {
            width: auto;
        }
    }
}
