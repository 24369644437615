body#tinymce {
    margin: 12px !important;

    table,
    .table,
    .mce-item-table {
        td {
            min-width: 2em;
        }
    }
}

.mce-toolbar {
    border: 1px solid $gray-light;
    background: $gray-lighter !important;
}

.fl-editor-field,
.mce-toolbar {
    i.mce-ico.mce-i-table,
    i.mce-ico.mce-i-tabledelete,
    i.mce-ico.mce-i-tableinsertrowbefore,
    i.mce-ico.mce-i-tableinsertrowafter,
    i.mce-ico.mce-i-tabledeleterow,
    i.mce-ico.mce-i-tableinsertcolbefore,
    i.mce-ico.mce-i-tableinsertcolafter,
    i.mce-ico.mce-i-tableinsertcolbefore,
    i.mce-ico.mce-i-tabledeletecol {
        font-family: 'tinymce', Arial !important;
    }
}
