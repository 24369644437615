/****************************************************
******* Breadcrumb (mostly on 2-column pages) *******
****************************************************/
.breadcrumbs {
    padding: 1em 0 .75em;
    font-size: $font-size-small;

    span {
        display: inline-block;
    }
}
