.woocommerce-info {
    border-color: $color-primary;

    &::before {
        color: $color-primary;
    }
}

.woocommerce span.onsale,
span.onsale {
    display: inline-block;
    position: absolute;
    top: 1em;
    right: 1em;
    margin: 0;
    padding: 0;
    width: 6.5em;
    height: 6.5em;
    min-width: none;
    min-height: none;
    background-color: $color-primary;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    line-height: 6.5em;
    overflow: hidden;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;

    .single-product & {
        left: 1em;
        right: auto;
    }
}
