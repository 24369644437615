.page-template-template-archive .hentry {
    @include make-sm-column(12);
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    border-bottom: 1px solid $brand-primary;

    @media screen and (min-width: $screen-is-min) and (max-width: $screen-is-max) {
        @include make-is-column(6);
    }

    .bypostauthor {}

    // Inner Wrap for post components
    .media {
        background: $gray-lighter;

        // Wrapper for post image
        .post-media {
            @media screen and (max-width: $screen-is-max) {
                display: block;
                padding: 0 0 .5em 0;
            }

            img {
                max-width: 300px;

                @media screen and (max-width: $screen-is-max) {
                    width: 100%;
                    max-width: 100%;
                }
            }

        }

        // Wrapper for post excerpt/content
        .media-body {
            padding: .5em;

            @media screen and (max-width: $screen-is-max) {
                display: block;
                width: auto;
            }
        }
    }
}
