.woocommerce {
    // Shop/category title and sort dropdown styles
    .product-header-wrap {
        @include make-row();
        margin-bottom: 2em;

        .woocommerce-products-header,
        .woocommerce-ordering {
            float: left;

            select {
                margin-top: 18px;

                @media screen and (min-width: $screen-sm-min) {
                    max-width: 300px;
                    float: right;
                }
                @media screen and (min-width: $screen-md-min) {
                    margin-top: 28px;
                }
            }
            @include make-is-column(6);
        }
    }

    // Category slider on shop page
    .product-categories-slider {
            @include clearfix();
            margin-bottom: 3em;

            .slick-arrow {
                position: absolute;
                top: 40%;
                z-index: 1100;
                color: rgba($gray-light, 0.5);
                cursor: pointer;
                font-size: 36px;
                transition: all 250ms ease-in-out;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);

                &:hover {
                    color: $gray-light;
                }

                &.arrow-prev {
                    left: 0;
                }
                &.arrow-next {
                    right: 0;
                }
            }
    }

    ul.products {
        @include make-row();

        li.product {
            @include make-xs-column(12);
            @include make-is-column(6);
            @include make-md-column(4);
            position: relative;
            margin: 0;
            margin-bottom: 3.25em;
            border: 1px solid transparent;
            text-align: center;
            transition: all 250ms ease-in-out;

            @media screen and (max-width: $screen-xs-max) {
                margin-bottom: 5em;
                font-size: 0.8em;
            }

            @media screen and (min-width: $screen-md-min) {

                &.type-product {
                    &:hover {
                        background: #fcfcfc;
                    }
                }
            }

            &.product-category {
                @include make-xs-column(6);
                @include make-is-column(4);
                margin-bottom: 0;
                font-size: $font-size-small;

                img {
                    margin: 0;
                }
            }

            &.has-post-thumbnail {
                img.wp-post-image {
                    margin: 0 auto;
                    width: auto;
                }
            }

            &.first {
                clear: none;
            }

            a {
                display: block;
            }

            .woocommerce-LoopProduct-wrapper {
                position: relative;
                padding: .75em 0 .33em;
            }

            a.button {
                display: inline-block;
                margin: 0 auto;
            }

            .product-cat {
                display: block;
                border-bottom: 2px dotted $gray-light;
                padding: .5em 0;
                margin-bottom: .25em;
                color: $gray-light;
                font-family: $font-family-serif;
                font-size: $font-size-small;
                line-height: 1.25;
                text-transform: uppercase;

                @media screen and (min-width: $screen-is-min) {
                    min-height: 3.6em;
                }
            }

            .woocommerce-loop-product__title {
                padding: .75em 0 0;
                color: darken($color-primary, 10%);
                font-size: $font-size-base*1.1;
                line-height: 1.2;

                @media screen and (min-width: $screen-is-min) {
                    min-height: 5em;
                }

                @media screen and (min-width: 530px) {
                    min-height: 4em;
                }
            }

            .price {
                display: block;
                margin: 0 0 .75em;
                color: $text-color;
                font-family: $font-family-serif;
                font-size: $font-size-base;
            }

            .star-rating {
                margin: 0 auto .5em;
            }

            // Price Styles for products on sale
            &.sale {
                .price {
                    ins {
                        display: inline-block;
                        width: calc(50% - 0.5em);
                        text-align: left;
                        text-decoration: none;
                    }
                    del {
                        display: inline-block;
                        margin-right: .5em;
                        padding-right: 1em;
                        border-right: 1px solid $color-secondary;
                        width: calc(50% - 0.5em);
                        color: $color-primary;
                        font-size: $font-size-base;
                        opacity: .5;
                        text-align: right;
                    }
                }
            }

            .product-link,
            .add_to_cart_button,
            .product_type_external,
            .product_type_grouped {
                display: inline-block;
                border: 1px solid $color-primary;
                border-radius: 2px;
                margin: .5em !important;
                padding: .25em .5em;
                background: none;
                color: $color-primary;
                font-family: $font-family-sans-serif;
                font-size: 12px;
                font-weight: 600;
                line-height: 1.2;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    background: none !important;
                    color: darken($color-primary, 10%) !important;
                }

                &::before {
                    padding-right: .25em;
                    content: '\e81c';
                    font-family: "custom-fontawesome";
                }

                &.added {
                    display: none;
                }
            }

            .added_to_cart {
                @extend .product-link;
                margin: 0;
                color: $text-color;
            }
        }
    }
}
