
.hero-image,
.empty-hero-image,
.header-post-thumbnail,
.header-no-image {
    @extend .bg-img-cover;
    min-height: 225px;
    @media screen and (min-width: $screen-sm-min) {
        min-height: 400px;
    }
    @media screen and (min-width: $screen-md-min) {
        min-height: 500px;
    }
    @media screen and (min-width: $screen-lg-min) {
        min-height: 600px;
    }
}
.container-hero {
    position:relative;
    height:100%;
    left:50%;
    transform:translateX(-50%);
    width:100%;
    .right-box {
        right:0;
        top:50%;
        transform:translateY(-50%);
    }
}
.mobile-hero-content {
    .hero-content {
        display:block;
        position:relative;
        width:100%;
        transform:none;
        top:auto;
        right:auto;
        left:auto;
        padding:20px;
    }
}
.hero-content {
        max-width:100%;
        background:rgba(221, 218, 218, 0.4);
        padding:20px 20px 20px 40px;
    @media screen and (min-width: $screen-sm-min) {
        max-width:50%;
    }
    @media screen and (min-width: $screen-lg-min) {
        max-width: 550px;
    }
    text-align: left;
    .hero-title {
        margin-bottom: .25em;
        color: #fff;
        font-weight: 800;
        font-size: 1.25em;
        color: $color-primary;

        @media screen and (min-width: $screen-sm-min) {
            font-size: 1.5em;
        }

        @media screen and (min-width: $screen-md-min) {
            font-size: 1.75em;
        }
        @media screen and (min-width: $screen-md-min) {
            font-size: 2.25em;
        }
    }

    .hero-text {

        color: #333333;
        font-size: 1em;

        @media screen and (min-width: $screen-md-min) {
            font-size: 1.1em;
        }
        @media screen and (min-width: $screen-lg-min) {
            font-size: 1.3em;
        }
    }

    .hero-cta {

        margin-top: 1em;
        border-radius: 0px;

    }
}

.header-no-image {
    background: $color-primary;
    > div {
        @extend .container;
    }
    p {
        width: 100%;
        padding: 1em;
        color: #fff;
        text-align: center;

        @media screen and (min-width: $screen-sm-min) {
            font-size: 1.5em;
        }
    }
}

.header-default-image {
    @extend .bg-img-cover;
    @extend .hero-area-content;
}
