.woocommerce {
    // Cart table styles
    table {
        &.cart {
            tr {
                &:nth-child(even) {
                    background: $color-tertiary;
                }
            }
            th,
            td {
                &.product-quantity {
                    .quantity {
                        @include clearfix();
                    }
                    .qty {
                        @media screen and (max-width: $screen-is-max) {
                            float: right;
                        }
                    }
                }

                // Coupopn code areas
                &.actions {
                    .coupon {
                        display: block;
                        width: 100%;

                        .input-text {
                            min-width: 150px;
                        }

                        .input-text,
                        .button {
                            margin-bottom: .5em;

                            @media screen and (max-width: $screen-xs-max) {
                                width: 100%;
                            }

                            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                                width: 48%;
                            }
                        }

                        .button {
                            @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }

    // Summary of cart totals
    .cart-collaterals {
        .cart_totals {
            table {
                tr {
                    &:nth-child(even) {
                        background: $color-tertiary;
                    }
                }
            }
        }
    }

    .wc-proceed-to-checkout {
        text-align: right;
    }
}
