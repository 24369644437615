.menu-item {
    .cart-count,
    .wishlist-count {
        display: inline-block;
        background: $color-tertiary;
        color: $text-color;
        font-size: $font-size-tiny;
        line-height: 1.33;
        width: 1.5em;
        height: 1.5em;
        z-index: 200;
        border: 1px solid $gray-lighter;
        border-radius: 100%;
        text-align: center;
        margin-left: .25em;
    }
}

.woocommerce-MyAccount-navigation-link {
    &.is-active {
        font-weight: 700;
    }
}
