.woocommerce {
    // Generic shop_table styles (cart, wishlist)
    table {
        &.shop_table {
            font-size: $font-size-small;

            th,
            td {
                &.product-thumbnail {
                    img {
                        width: 100%;
                        min-width: 80px;
                        max-width: 100px;
                    }
                    @media screen and (max-width: $screen-sm-max) {
                        display: none;
                    }
                }

                &.product-remove {

                    padding: 16px 12px !important;

                    &::before {
                        content: none;
                    }

                    a {
                        width: auto;
                        text-align: left;

                        &::before {
                            display: inline-block;
                            margin: -5px 5px 0 0;
                            color: $text-color;
                            content: attr(aria-label) ": ";
                            font-size: $font-size-small;
                            font-weight: normal;
                            vertical-align: middle;

                            @media screen and (min-width: $screen-sm-min) {
                                display: none;
                            }
                        }

                        &:hover {
                            @media screen and (max-width: $screen-is-max) {
                                color: $text-color !important;
                                background: none;
                            }
                        }
                    }
                }
				
				&.product-name {
                    .delivery-time-info {
                        font-size: $font-size-tiny;
                    }
                }

                &.product-price {
                    .unit-price {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
