/****************************************************
********** General Sidebar & Widget Styles **********
****************************************************/
.sidebar {

    .widget {
        margin-bottom: 1em;
    }

    ul {
        @extend .nav;

        li {
            border-bottom: 1px solid $color-tertiary;

            &:last-child {
                border-bottom: none;
            }

            a {
                display: block;
                padding: .75em;
            }

            // Shrink padding for parents, for better relation to children
            &.menu-item-has-children {
                > a {
                    padding-bottom: 0;
                }
            }

            // Styles for active menu items
            &.current-menu-item,
            &.current-post-parent,
            &.current_page_item,
            &.current_page_parent {

                > a {
                    font-weight: bold;
                    color: $navbar-default-link-hover-color;
                    background: $navbar-default-link-hover-bg;
                }
            }

            // Shrink padding of submenu items
            ul {
                li {
                    > a {
                        padding: .5em .75em .5em 1.5em;
                    }
                }
            }
        }
    }
}
