.fl-module-advanced-content-slider-module {
    .fl-module-content {
        .fl-advanced-content-slider-wrap {
            position: relative;

            .slick-track {
                padding: .5em 0;
            }

            .slick-arrow {
                position: absolute;
                top: 50%;
                margin-top: -.5em;
                z-index: 100;

                display: none;

                display: block;

                &.slick-prev {
                    left: -$grid-gutter-width/2;
                }

                &.slick-next {
                    right: -$grid-gutter-width/2;
                }
            }

            .fl-advanced-content-slider-post {
                margin: .75em;
                background: #efefef;
                transition: transform 300ms ease-in-out;
                transform: scale(1);
                -ms-transform: scale(1);

                &.slick-center {
                    transform: scale(1.1);
                    -ms-transform: scale(1.1);
                }

                .fl-advanced-content-slider-post-title,
                .fl-advanced-content-slider-post-content {
                    padding: .5em;
                }
            }
        }
    }
}
