#cookie-notice {
    background: $color-secondary !important;

    .cookie-notice-container {
        font-size: $font-size-small;

        @media screen and (min-width: $screen-sm-min) {
            font-size: $font-size-base;
        }

        #cn-notice-text {
            vertical-align: middle;
        }

        a#cn-accept-cookie {
            @extend .cta-btn;
            display: block;
            margin: .5em auto 0;
            max-width: 100px;
            padding: .25rem .75rem !important;

            @media screen and (min-width: $screen-is-min) {
                display: inline-block;
                padding: .25rem .75rem !important;
            }
        }
    }
}
