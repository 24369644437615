// Error 404 Page Styles
.error404 {
    main {
        text-align: center;
    }
    .error404-content {
        padding-top: 1em;

        .search-form {
            padding: 1em;
        }
    }
}
#facebook-posts-carousel {
    .facebook-posts-carousel-item {
        text-align: center;
        .facebook-posts-date {
            font-weight:bold;
            display:block;
            font-size: 25px;
            margin-bottom:20px;
        }
        .facebook-posts-image {
            max-width:200px;
            display:block;
            margin:0 auto;
            margin-bottom:10px;
        }
        .facebook-posts-message {
            display:block;
            margin:0 60px;
        }
    }
}
.fl-row>div {
    padding-top: 2em;
    padding-bottom: 2em;
}

#ci-list {
    ul {
        padding-left:40px;
        li {
            position: relative;
            list-style-type: none;
            margin:20px;
            &:after {
                content:'';
                background-image: url(../images/bulletpoint.svg);
                background-size: contain;
                width:50px;
                height:50px;
                background-repeat: no-repeat;
                display:block;
                position:absolute;
                height:25px;
                width:30px;
                top:0;
                left:0;
                transform:translateX(calc(-100% - 10px));
                -ms-transform:translateX(calc(-100% - 10px));
            }
        }
    }
}

.teaserbox, .teaserbox-support {

    .fl-module-content {

        // min-height: 512px;
        min-height: 756px;

        box-shadow: 0 0.5px 0 0 #ffffff inset, 0 1px 20px 0 #B3B3B3;

        .fl-callout-content {

            .fl-callout-title {

                margin: 1.5em 0 1em 0;

                a {
                    color: $color-primary !important;
                    text-transform: uppercase;
                    &:hover {
                        text-decoration:underline;
                    }
                }

            }

            .fl-callout-photo {

                .fl-photo {

                    .fl-photo-content {

                        margin-top: 1.5em;

                        a {

                            display: block;
                            position: relative;

                            // &::after {
                            //     content: '';
                            //     display: block;
                            //     right: 15px;
                            //     bottom: 15px;
                            //     width: 46px;
                            //     height: 52px;
                            //     position: absolute;
                            //     background-image: url(../images/coaching_schneiders_icon.png);
                            // }

                        }

                    }

                }

            }

            .fl-callout-text-wrap {

                padding: 0 1.5em;

                .fl-callout-text {

                    min-height: 145px;

                    p {

                        line-height: 150%;

                    }


                }

                a {

                    margin: 1.5em 0 1em 0;
                    color: $color-primary !important;
                    text-transform: uppercase;
                    font-weight: 300;
                    display: block;
                    &:hover {
                        text-decoration:underline;
                    }
                    &::after {
                        margin-left: 0.5em;
                        display: inline-block;
                        content: '▶';
                    }

                }

            }

        }

    }

}

.teaserbox-support { 
    .fl-module-content {
        min-height: 100px;

        .fl-callout-content {

            .fl-callout-title {

                margin: 1.5em 0 1em 0;

                a {
                    color: $color-primary !important;
                    text-transform: uppercase;
                    &:hover {
                        text-decoration:underline;
                    }
                }

            }

            .fl-callout-photo {

                .fl-photo {

                    .fl-photo-content {

                        // margin-top: 1.5em;

                        a {

                            display: block;
                            position: relative;

                            // &::after {
                            //     content: '';
                            //     display: block;
                            //     right: 15px;
                            //     bottom: 15px;
                            //     width: 46px;
                            //     height: 52px;
                            //     position: absolute;
                            //     background-image: url(../images/coaching_schneiders_icon.png);
                            // }

                        }

                    }

                }

            }

            .fl-callout-text-wrap {

                padding: 0 1.5em;

                .fl-callout-text {

                    min-height: auto !important;

                    p {

                        line-height: 150%;

                    }


                }

                a {

                    margin: 1.5em 0 1em 0;
                    color: $color-primary !important;
                    text-transform: uppercase;
                    font-weight: 300;
                    display: block;
                    &:hover {
                        text-decoration:underline;
                    }
                    &::after {
                        margin-left: 0.5em;
                        display: inline-block;
                        content: '▶';
                    }

                }

            }

        }
    }
}

.anchor-fix {

    padding-top: 125px;
    margin-top: -125px;

}

.small-red-box {

    background-color: $color-septenary;

    .fl-rich-text {

        ul {

            padding-left: 0px !important;
            list-style: disc !important;

            > li {

                list-style: disc !important;
                color: #fff;
                font-size: 0.8em;
                margin: 15px !important;

                &:after {
                    display: none;
                    visibility: hidden;
                }

            }

        }

    }

}
