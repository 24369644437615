// Search form
.search-form {
    @extend .form-inline;
}
.search-form label {
    @extend .form-group;
    font-weight: normal;
}
.search-form .search-field {
    @extend .form-control;
}
.search-form .search-submit {
    @extend .btn;
    @extend .btn-default;
}

// Caldera Forms
.caldera-grid {

    .form-control {

        padding: 12px !important;
        border: 0px !important;
        border-radius: 0px !important;

    }

    input {

        &[type="text"], &[type="email"] {
            height: 46px !important;
        }

    }

    .form-group {
        &.has-error {

        }

        .control-label,
        .field_required {
            display: none;
            color: $color-primary !important;

            .no-placeholder &{
                display: inline-block;
            }
        }
    }

    .alert.alert-success {
        padding: 1em;
        background: #fff;
        background: rgba(255, 255, 255, 0.75);
        color: $color-secondary;
        text-align: center;
        border-radius: 0;
        border:none;
        text-shadow: none;

        @media screen and (min-width: $screen-sm-min) {
            padding: 2em;
        }
    }

    input[type='checkbox'] {
        top: 4px;
    }
}

.form-group {

    position: relative;

    .caldera_ajax_error_block {
        display: none !important;
        margin: 0 !important;
        background-color: rgba(255,255,255,0.8);
        border-radius: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        font-size: 0.65em;
        padding: 0.25em 0.5em;
    }

    &.textarea-wrapper {

        .caldera_ajax_error_block {
            top: 5px !important;
            transform: translateY(0%) !important;
        }

    }

    &.has-error {

        .caldera_ajax_error_block {
            display: block !important;
        }

    }

}

