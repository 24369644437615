.woocommerce {
    &.single-product {
        .breadcrumbs {
            span {
                > span[property="name"] {
                    @media screen and (max-width: $screen-sm-max) {
                        display: none;
                    }
                }
            }
        }
        #content {
            @media screen and (min-width: $screen-is-min) {
                padding-top: .5em;
            }

            > .product {
                @include make-row();

                .woocommerce-product-gallery {
                    overflow: hidden;
                    text-align: center;
                    margin: 1em 0 0;

                    @media screen and (max-width: $screen-xs-max) {
                        float: none;
                        width: 100%;
                    }

                    @media screen and (min-width: $screen-is-min) and (orientation: landscape) and (max-width: $screen-is-max){
                        width: 40%;
                        float: left;
                    }

                    @include make-sm-column(5);


                    figure.woocommerce-product-gallery__wrapper {
                        img {
                            display: inline-block;
                            width: auto;
                            max-height: 550px;

                            @media screen and (max-width: $screen-is-max) {
                                max-height: 275px;
                            }
                        }
                    }
                    .flex-control-nav {
                        margin: 1em 0;

                        li {
                            width: 15.8%;
                            margin: .5%;

                            &:nth-child(4n+1) {
                                clear: none;
                            }

                            @media screen and (max-width: $screen-is-max) {
                                &:first-child, &:nth-child(6n+1) {
                                    margin-left: 0;
                                }
                                &:nth-child(6n+0) {
                                    margin-right: 0;
                                }
                            }

                            @media screen and (min-width: $screen-sm-min) {
                                width: 24.25%;
                                margin: .5%;

                                &:first-child, &:nth-child(4n+1) {
                                    margin-left: 0;
                                }
                                &:nth-child(4n+0) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }

                .summary {
                    @media screen and (max-width: $screen-xs-max) {
                        float: none;
                        width: 100%;
                    }
                    @media screen and (min-width: $screen-is-min) and (orientation: landscape) and (max-width: $screen-is-max){
                        width: 56%;
                        float: left;
                        margin-left: 4%;
                    }
                    @include make-sm-column(7);

                    .product_title.entry-title {
                        font-size: $font-size-large;

                        @media screen and (min-width: $screen-md-min) {
                            font-size: $font-size-h2;
                        }
                    }

                    .price {
                        display: inline-block;
                        margin-bottom: 0;

                        &.price-unit {
                            display: inline-block;
                            margin-left: .5em;
                            color: $text-color;
                            font-size: $font-size-tiny;

                            &::before {
                                content: '(';
                            }

                            &::after {
                                content: ')';
                            }
                        }

                        ins {
                            text-decoration: none;
                        }
                    }

                    .product-units {
                        display: block;
                    }

                    table.variations {
                        margin-bottom: 0;

                        .label, .value {
                            display: block;
                        }
                        .reset_variations {
                            display: block !important;
                        }
                    }

                    .single_add_to_cart_button {
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }

                    .add_to_wishlist {
                        @extend %btn;
                        @extend %cta-btn;
                    }

                    .product_meta {
                        > span {
                            display: block;
                        }
                    }
                }

                .woocommerce-tabs {
                    ul.wc-tabs {
                        display: none;
                    }
                    .woocommerce-Tabs-panel {
                        border-top: 1px solid $gray-lighter;
                        padding-left: $grid-gutter-width/2;
                        padding-right: $grid-gutter-width/2;

                        .shop_attributes {
                            text-align: left;

                            tr:nth-child(even) td,
                            tr:nth-child(even) th {
                                background: rgba($color-tertiary, 0.25);
                            }
                        }
                    }
                }

                    .upsells,
                    .related {
                        padding-left: $grid-gutter-width/2;
                        padding-right: $grid-gutter-width/2;
                        clear: both;
                        padding-top: .5em;
                        border-top: 1px solid $gray-lighter;

                        li.product {
                            @include make-xs-column(6);
                            @include make-sm-column(4);
                            position: relative;
                            margin: 0 0 2em;
                            min-height: 300px;
                            text-align: center;
                            clear: none;

                            > a {
                                display: block;
                                position: absolute;
                                right: 0;
                                bottom: 2.25em;
                                left: 0;

                                img.wp-post-image {
                                    margin: 0 auto;

                                    &.size-shop_catalog {
                                        padding-left: $grid-gutter-width/2;
                                        padding-right: $grid-gutter-width/2;
                                        width: auto;
                                        max-height: 200px;
                                    }
                                }

                                &.add_to_cart_button {
                                    display: inline-block;
                                    margin: 0 auto;
                                    left: 1em;
                                    right: 1em;
                                    bottom: 0;
                                }
                            }

                            .star-rating {
                                margin: 0 auto .5em;
                            }
                        }
                    }
            }
        }
    }
}

.remodal {
    padding: 1.25em !important;
}
