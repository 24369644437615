.woocommerce {
    // Wishlist table styles
    table {
        &.wishlist_table {
            tr {
                &.cart_item {
                    &:nth-child(even) {
                        background: $color-tertiary;
                    }
                }
            }

            th,
            td {
                &.actions,
                &.wishlist-empty {
                    text-align: center !important;

                    &::before {
                        display: none;
                    }
                }
            }

            tfoot {
                display: none;
            }

            a.remove_from_wishlist,
            a.remove_from_wishlist:hover {
                background: none !important;
                color: $color-primary !important;
            }
        }
    }
}
