.woocommerce-account {
    .woocommerce-MyAccount-navigation {
        width: auto;
        float: none;
    }
    .woocommerce-MyAccount-content {
        width: auto;
        float: none;
    }
}
