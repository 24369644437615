.woocommerce {
    input[type="search"],
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="tel"],
    input[type="password"],
    select,
    .select2 .selection,
    textarea {
        @extend .form-control;
        max-width: 100%;
    }

    .select2 {
        .select2-selection {
            border: none;
        }
        .select2-selection__arrow {
            height: 40px;
        }
    }

    form {
        .form-row {
            @extend .form-group;

            label {
                line-height: 1.5;
            }
        }
    }

    .woocommerce-account-fields label span,
    .wc-terms-and-conditions label {
        display: inline-block;
        margin: 0;
        max-width: calc(100% - 22px);
        float: right;
    }

    .woocommerce-account-fields label span {
        margin-top: -8px;
    }
}

.site-search {
    padding-top: .5em;
    label {
        width: 65%;

        input.search-field {
            padding: .25em 1em;
            height: ($line-height-computed + $padding-base-vertical);
            max-width: calc(100% - 24px);
            font-size: $font-size-small;
        }
    }
    input.search-submit {
        float: right;
        font-size: $font-size-small !important;
    }
}
