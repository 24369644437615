// Grid system
.main {
    // @include make-sm-column($main-sm-columns);
    .sidebar-primary & {
        @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
        @include make-sm-column-push($sidebar-sm-columns);
    }
}

.sidebar {
    @include make-sm-column($sidebar-sm-columns);
    @include make-sm-column-pull($main-sm-columns - $sidebar-sm-columns);
}

.no-sidebar-primary {
    .post-header,
    .page-header,
    .breadcrumbs,
    .error404-content {
        @extend .container;
    }
}

// Grid system

@mixin make-container($gutter: $grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left:  floor(($gutter / 2));
    padding-right: ceil(($gutter / 2));

    @media (min-width: $screen-sm-min) {
        width: $container-sm;
    }
    @media (min-width: $screen-md-min) {
        width: $container-md;
    }
    @media (min-width: $screen-lg-min) {
        width: $container-lg;
    }
    @include clearfix;
}

// reinit origin mixin for create grid columns
@mixin make-grid-columns($i: 1, $list: ".col-xs-#{$i}, .col-is-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
    @for $i from (1 + 1) through $grid-columns {
        $list: "#{$list}, .col-xs-#{$i}, .col-is-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
    }
    #{$list} {
        position: relative;
        // Prevent columns from collapsing when empty
        min-height: 1px;
        // Inner gutter via padding
        padding-left: ($grid-gutter-width / 2);
        padding-right: ($grid-gutter-width / 2);
    }
}

@include make-grid-columns;

// Function that creates the custom intermediate small grid
@media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
    @include make-grid(is);
}

// Add visibility "visible-is" class for the custom grid
@media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
    @include responsive-visibility('.visible-is');
}

// Add hidden "hidden-is" class for the custom grid
@media (min-width: $screen-is-min) and (max-width: $screen-is-max) {
    @include responsive-invisibility('.hidden-is');
}

// Define the "visible-is" class as hidden in all other grid widths (Except in the custom grid).
@media (min-width: $screen-sm-min) {
    @include responsive-invisibility('.visible-is');
}

// @media (max-width: $screen-xs-max) {
//   @include responsive-invisibility('.visible-is');
// }


// Generate the intermediate small columns
@mixin make-is-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left:  ($gutter / 2);
    padding-right: ($gutter / 2);

    @media (min-width: $screen-is-min) {
        float: left;
        width: percentage(($columns / $grid-columns));
    }
}
@mixin make-is-column-offset($columns) {
    @media (min-width: $screen-is-min) {
        margin-left: percentage(($columns / $grid-columns));
    }
}
@mixin make-is-column-push($columns) {
    @media (min-width: $screen-is-min) {
        left: percentage(($columns / $grid-columns));
    }
}
@mixin make-is-column-pull($columns) {
    @media (min-width: $screen-is-min) {
        right: percentage(($columns / $grid-columns));
    }
}
